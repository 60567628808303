export const json1 = {
  header: {
    title: '版块名称',
  },
  goodsList: [
    {
      name: '商品名称',
      price: '0.00',
      img: '',
      size: '174*156',
      type: '2',
      url: ''
    }, {
      name: '商品名称',
      price: '0.00',
      img: '',
      size: '174*156',
      type: '2',
      url: ''
    }, {
      name: '商品名称',
      price: '0.00',
      img: '',
      size: '174*156',
      type: '2',
      url: ''
    }, {
      name: '商品名称',
      price: '0.00',
      img: '',
      size: '174*156',
      type: '2',
      url: ''
    }, {
      name: '商品名称',
      price: '0.00',
      img: '',
      size: '174*156',
      type: '2',
      url: ''
    }, {
      name: '商品名称',
      price: '0.00',
      img: '',
      size: '174*156',
      type: '2',
      url: ''
    }, {
      name: '商品名称',
      price: '0.00',
      img: '',
      size: '174*156',
      type: '2',
      url: ''
    }, {
      name: '商品名称',
      price: '0.00',
      img: '',
      size: '174*156',
      type: '2',
      url: ''
    }
  ]
}
