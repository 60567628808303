<template>
    <el-dialog class="popup_dialog" title="新增楼层" :visible.sync="isPopupAdd" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" :destroy-on-close="false" width="800px">
        <el-form ref="form" :model="form" :rules="rules" label-width="82px" size="small">
            <el-form-item label="楼层类型：" prop="type">
                <el-radio-group v-model="form.type" class="templateRadio">
                    <el-radio label="1">
                        <el-image :src="templateImg01" fit="contain"></el-image>
                        模板1
                    </el-radio>
                </el-radio-group>
            </el-form-item>

            <div class="flex2">
                <el-form-item label="楼层名称：" prop="name">
                    <el-input v-model="form.name" autocomplete="off" placeholder="请输入楼层名称"></el-input>
                </el-form-item>

                <el-form-item label="排序：" prop="sort">
                    <el-input v-model.number="form.sort" autocomplete="off" placeholder="请输入排序"></el-input>
                </el-form-item>
            </div>

            <el-form-item label="是否显示：" prop="isShow">
                <el-switch v-model="form.isShow"  :active-value="1" :inactive-value="2"></el-switch>
            </el-form-item>

            <el-form-item>
                <el-button type="primary" @click="submitForm('form')" :loading="isSubmitLoading">提  交</el-button>
                <el-button @click="close">关  闭</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
import {postAdminStoreFloor} from "../../../../api/admin/store";
import {json1} from './json'
export default {
  name: "floorAdd",
  props: {
    isPopupAdd: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      form: {
        type: '1',
        name: '',
        sort: null,
        isShow: 2,
        textArea: JSON.stringify(json1)
      },
      rules: {
        type: [
          {required: true, message: '请选择楼层类型', trigger: 'change'}
        ],
        name: [
          {required: true, message: '请输入楼层名称', trigger: 'blur'}
        ],
        sort: [
          {required: true, message: '请输入排序', trigger: 'blur'}
        ],
      },
      isSubmitLoading: false,
      templateImg01: require('../../../../assets/img/template/template01.png'),
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.isSubmitLoading = true
          postAdminStoreFloor({
            data: this.form
          }).then(res => {
            this.isSubmitLoading = false
            if (res.code === 0) {
              this.$message({
                message: '新增楼层成功',
                type: 'success',
                showClose: true,
              });
              this.$refs[formName].resetFields()
              this.$emit('success')
            }
          }).catch(() => {
            this.isSubmitLoading = false
          })
        }
      })
    },
    close () {
      this.$emit('close')
    }
  },
  components: {}
}
</script>
